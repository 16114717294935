import PropTypes from "prop-types"
import React from "react"
import ConfirmNavLink from "../../../common/confirm-nav-link/confirm-nav-link"
import styles from "./website-footer.module.scss"
import { MdRoom, MdEmail } from "react-icons/md"
import classNames from "classnames"

const WebsiteFooter = ({ siteTitle }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.websiteFooterLogo,
            "website-footer-logo"
          )}
        >
          <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
        </div>
        <section>
          <h6>Forest History Association of Alberta</h6>
          <div className={styles.info}>
            <p>
              <span>
                <MdRoom />
              </span>
              P.O. Box 48099<br />
              Village Landing Post Office<br />
              St. Albert, AB T8N 5V9
              {/* 22 Hutchinson Place St. <br /> Albert, AB T8N 6R3 */}
            </p>
            <p>
              <span>
                <MdEmail />
              </span>
              fhaa@albertaforesthistory.ca
            </p>
          </div>
        </section>
        <section>
          <h6>About</h6>
          <div className={styles.menu}>
            <ConfirmNavLink to={"/about"}>About Us</ConfirmNavLink>
            <ConfirmNavLink to={"/membership"}>Membership Plan</ConfirmNavLink>
            <ConfirmNavLink to={"/newsletters"}>Newsletters</ConfirmNavLink>
            <ConfirmNavLink to={"/meetings"}>Meetings</ConfirmNavLink>
            <ConfirmNavLink to={"/about"}>Bylaws</ConfirmNavLink>
            <ConfirmNavLink to={"/"}>Media Catalogue</ConfirmNavLink>
          </div>
        </section>
        <section>
          <h6>Support</h6>
          <div className={styles.menu}>
            <ConfirmNavLink to={"/contact"}>Contact</ConfirmNavLink>
            <ConfirmNavLink to={"/links"}>Links</ConfirmNavLink>
            <ConfirmNavLink to={"/privacy"}>Privacy Policy</ConfirmNavLink>
          </div>
        </section>
      </div>
      <div className={styles.copyright}>
        © 2022 Forest History Association of Alberta. All Rights Reserved.
      </div>
    </footer>
  )
}

WebsiteFooter.propTypes = {
  siteTitle: PropTypes.string,
}

WebsiteFooter.defaultProps = {
  siteTitle: ``,
}

export default WebsiteFooter
