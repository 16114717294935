import PropTypes from "prop-types"
import React, { useState } from "react"
import ConfirmNavLink from "../../../common/confirm-nav-link/confirm-nav-link"
import styles from "./website-header.module.scss"
import { MdMenu } from "react-icons/md"
import WebsiteMenu from "../website-menu/website-menu"
import classNames from "classnames"
import auth from "../../../../services/auth"

const WebsiteHeader = ({ siteTitle }) => {
  const [isToggleOn, setIsToggleOn] = useState(false)

  const handleClick = () => {
    setIsToggleOn(!isToggleOn)
  }

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.websiteHeaderLogo,
            "website-header-logo"
          )}
        >
          <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
        </div>
        <div className={styles.burgerMenu} onClick={handleClick}>
          <MdMenu />
        </div>

        <nav
          className={classNames(
            styles.navigation,
            isToggleOn ? styles.openMenu : " "
          )}
        >
          <ConfirmNavLink toLocal={"/"} activeClassName={styles.active}>
            Media Catalogue
          </ConfirmNavLink>
          <ConfirmNavLink toLocal={"/about"} activeClassName={styles.active}>
            About
          </ConfirmNavLink>
          <ConfirmNavLink
            toLocal={"/membership"}
            activeClassName={styles.active}
          >
            Membership
          </ConfirmNavLink>
          <ConfirmNavLink toLocal={"/contact"} activeClassName={styles.active}>
            Contact
          </ConfirmNavLink>
          {auth.isLoggedIn() ? (
            ""
          ) : (
               <ConfirmNavLink toLocal={"/app/login"} activeClassName={styles.active}>
                 Login
              </ConfirmNavLink>
          )}
          <div className={styles.userMenu}>
            <WebsiteMenu className={styles.userName} />
          </div>
        </nav>
      </div>
    </header>
  )
}

WebsiteHeader.propTypes = {
  siteTitle: PropTypes.string,
}

WebsiteHeader.defaultProps = {
  siteTitle: ` `,
}

export default WebsiteHeader
